import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import './assets/fonts/fonts.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'primeflex/primeflex.min.css';
import { PrimeReactProvider } from 'primereact/api';
import Layout from './components/layout/Layout';
import Dashboard from './pages/dashboard/Dashboard';
import Locations from './pages/locations/Location';
import './i18n';
import LocationDetails from './pages/location-details/LocationDetails';
import Actions from './pages/actions/Actions';
import Risk from './pages/risk/Risk';
import Report from './pages/report/Report';
const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <Dashboard />,
      },
      {
        path: 'locations',
        element: <Locations />,
      },
      {
        path: 'location-details',
        element: <LocationDetails />,
      },
      {
        path: 'risk',
        element: <Risk />,
      },
      {
        path: 'report',
        element: <Report />,
      },
      {
        path: 'actions',
        element: <Actions />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PrimeReactProvider>
      <RouterProvider router={router} />
    </PrimeReactProvider>
  </React.StrictMode>
);

reportWebVitals();
