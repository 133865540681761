export const items2 = [
  {
    id: 1,
    text: 'Single location',
  },
  {
    id: 2,
    text: 'Climate risk',
  },
  {
    id: 3,
    text: 'Deforestation risk',
  },
];
export const items1 = [
  {
    id: 1,
    text: 'CSRD',
    description: 'Corporate Sustainability Reporting Directive',
  },
  {
    id: 2,
    text: 'CSRD',
    description: 'Corporate Sustainability Reporting Directive',
  },
  {
    id: 3,
    text: 'CSRD',
    description: 'Corporate Sustainability Reporting Directive',
  },
];
