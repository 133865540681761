import React from 'react';
import { SelectButton as PrimeSelectButton } from 'primereact/selectbutton';
import './SelectButton.scss';
import Card from '../Card/Card';

export const SelectButton = ({ options, returnValue }) => {
  const [value, setValue] = React.useState(options[0]);

  return (
    <Card radius='large' color='white' className='select-button-container p-1'>
      <PrimeSelectButton
        value={value}
        onChange={(e) => {
          if (e.value) {
            returnValue(e.value);
            setValue(e.value);
          }
        }}
        options={options}
      />
    </Card>
  );
};
