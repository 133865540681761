import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import { useSearchParams } from 'react-router-dom';
import markerIcon from '../../../../assets/marker.png';
import { generateLocations } from '../../../../utils/generators';
import './Mapbox.scss';
import useViewport from '../../../../hooks/useViewport';
import SizeDetector from '../../../../utils/size-detector';

import Card from '../../../../components/Card/Card';
import Typography from '../../../../components/Typography/Typography';

mapboxgl.accessToken =
  'pk.eyJ1Ijoia2hhbGVkNzUwIiwiYSI6ImNsdm9kajV0YTBpeDcycW82MW5icDM1aWYifQ.kPUahKxxSidr5l0ARsTsRg';

const viewportStyles = {
  '4k': {
    markerSize: '30px',
    titleSize: 'text-5xl',
    exploreTitle: 'text-8xl',
    exploreDsc: 'text-2xl mt-6',
  },
  qhd: {
    markerSize: '30px',
    titleSize: 'text-3xl',
    exploreTitle: 'text-7xl',
    exploreDsc: 'text-lg mt-4',
  },
  fhd: {
    markerSize: '25px',
    titleSize: 'text-lg',
    exploreTitle: 'text-6xl',
    exploreDsc: 'text-sm mt-3',
  },
  hdtv: {
    markerSize: '20px',
    titleSize: 'text-md',
    exploreTitle: 'text-5xl',
    exploreDsc: 'text-sm mt-3',
  },
  hd: {
    markerSize: '15px',
    titleSize: 'text-sm',
    exploreTitle: 'text-4xl',
    exploreDsc: 'text-sm mt-3',
  },
  default: {
    markerSize: '20px',
    titleSize: 'text-lg',
    exploreTitle: 'text-xl',
    exploreDsc: 'text-lg',
  },
};

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

function createGraticule() {
  const features = [];
  for (let lon = -180; lon <= 180; lon += 10) {
    features.push({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [
          [lon, -90],
          [lon, 90],
        ],
      },
    });
  }
  for (let lat = -90; lat <= 90; lat += 10) {
    features.push({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [
          [-180, lat],
          [180, lat],
        ],
      },
    });
  }
  return features;
}

export default function Map() {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [searchParams] = useSearchParams();
  const locationId = searchParams.get('location_id');

  const { width } = useViewport();
  const size = SizeDetector(width);

  const { mapHeight, mapWidth, markerSize } =
    viewportStyles[size] || viewportStyles.default;

  const [viewport, setViewport] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 2,
    width: mapWidth,
    height: mapHeight,
  });

  const [locations, setLocations] = useState([]);
  const [, setSelectedLocation] = useState(null);
  const markersRef = useRef([]);

  const fetchLocations = useCallback((latitude, longitude) => {
    const locations = generateLocations({ radius: 3000, latitude, longitude });
    setLocations(locations);
  }, []);

  const debouncedFetchLocations = useMemo(
    () => debounce(fetchLocations, 500),
    [fetchLocations]
  );

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setViewport((prevViewport) => ({
          ...prevViewport,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          zoom: 1,
        }));
      },
      () => {
        console.error('Error getting user location');
      }
    );
  }, []);

  useEffect(() => {
    if (locationId && locations.length > 0) {
      const selectedLocation = locations.find(
        (location) => location.properties.id === locationId
      );

      if (selectedLocation) {
        setViewport((prevViewport) => ({
          ...prevViewport,
          latitude: selectedLocation.geometry.coordinates[1],
          longitude: selectedLocation.geometry.coordinates[0],
        }));
        setSelectedLocation(selectedLocation);
      }
    }
  }, [locationId, locations]);

  useEffect(() => {
    if (mapContainerRef.current && !mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/khaled750/clyegarl800pg01pnbj0d2izw',
        center: [viewport.longitude, viewport.latitude],
        zoom: viewport.zoom,
        projection: 'globe',
      });

      mapRef.current.on('load', () => {
        mapRef.current.addSource('graticule', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: createGraticule(),
          },
        });

        mapRef.current.addLayer({
          id: 'graticule',
          type: 'line',
          source: 'graticule',
          layout: {},
          paint: {
            'line-color': '#888',
            'line-width': 1,
          },
        });
      });

      mapRef.current.on('move', () => {
        const { lng, lat } = mapRef.current.getCenter();
        const vp = {
          ...viewport,
          latitude: lat,
          longitude: lng,
          zoom: mapRef.current.getZoom(),
        };

        setViewport(vp);
        debouncedFetchLocations(vp.latitude, vp.longitude);
      });
    }
  }, [
    viewport.longitude,
    viewport.latitude,
    viewport.zoom,
    viewport,
    debouncedFetchLocations,
  ]);

  useEffect(() => {
    if (mapRef.current) {
      // Remove existing markers
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];

      // Add new markers
      locations.forEach((location) => {
        const { coordinates } = location.geometry;
        if (coordinates && coordinates.length === 2) {
          const marker = new mapboxgl.Marker({
            element: createMarkerElement(location),
          })
            .setLngLat([coordinates[0], coordinates[1]])
            .addTo(mapRef.current);

          marker
            .getElement()
            .addEventListener('click', () => setSelectedLocation(location));
          markersRef.current.push(marker);
        }
      });
    }
    function createMarkerElement() {
      const markerElement = document.createElement('div');
      const markerImage = document.createElement('img');
      markerImage.src = markerIcon;
      markerImage.alt = 'Marker Icon';
      markerImage.style.width = markerSize;
      markerElement.appendChild(markerImage);
      return markerElement;
    }
  }, [locations, markerSize]);

  return (
    <Card radius='large' bgColor='dark'>
      <div className='w-full h-full flex'>
        <div className='explore-section '>
          <Typography
            text='dashboard.explore.title'
            variant='h2'
            color='white'
          />
          <Typography
            dangerHtml
            color='white'
            text='dashboard.explore.description'
            variant='body1'
          />
        </div>
        <div
          className='w-full h-full map-layer'
          ref={mapContainerRef}
          id='map'
        />
      </div>
    </Card>
  );
}
