import { useState } from 'react';
import Button from '../../../components/Button/Button';
import TextField from '../../../components/TextField/TextField';
import RadioButton from '../../../components/RadioButton/RadioButton';
import './Location.scss';

const options = ['Own', 'Upstream', 'Downstream'];

const LocationsTableHeader = ({ onSearch, noLocations }) => {
  const [searchQuery, setSearchQuery] = useState(null);

  const onSearchChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/\s+/g, ' ');

    const sq = { ...searchQuery, [name]: trimmedValue };
    setSearchQuery(sq);
    onSearch(sq);
  };

  return (
    <div className='location-table-header'>
      <div className='h-full grid-column-span-5'>
        <TextField
          name='name'
          value={searchQuery?.['name']}
          placeholder='Search'
          type='text'
          onChange={onSearchChange}
          variant='light'
        />
      </div>

      <div className='h-full grid-column-span-3 '>
        <TextField
          name='countryCode'
          value={searchQuery?.['countryCode']}
          placeholder='Country'
          type='text'
          onChange={onSearchChange}
          rounded
          className='h-full'
          variant='light'
        />
      </div>

      <div className='h-full grid-column-span-4'>
        <Button disabled={noLocations} color='dark' size='small'>
          Export list
        </Button>
      </div>

      <div className='h-full grid-column-span-8'>
        <RadioButton
          title='site type'
          options={options}
          variant='light'
          extraClassNames='align-items-center justify-content-around'
        />
      </div>

      <div className='h-full grid-column-span-4'>
        <Button color='dark' size='small'>
          Add location
        </Button>
      </div>
    </div>
  );
};

export default LocationsTableHeader;
