export default function SizeDetector(width) {
  let size;

  switch (true) {
    case width < 1190:
      size = 'sd';
      return size;
    case width < 1620:
      size = 'hd';
      return size;
    case width < 1920:
      size = 'hdtv';
      return size;
    case width < 2560:
      size = 'fhd';
      return size;
    case width < 3840:
      size = 'qhd';
      return size;
    default:
      size = '4k';
      return size;
  }
}
