import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';
import LabelBox from './LabelBox/LabelBox';

export default function ActionCards() {
  const { t } = useTranslation();

  return (
    <div
      style={{ gap: '1vh' }}
      className={classNames(
        'flex',
        'flex-column',
        'justify-content-between',
        'w-full',
        'h-full'
      )}
    >
      <LabelBox
        text={t('dashboard.actions.reports.title')}
        description={t('dashboard.actions.reports.description')}
      />
      <LabelBox
        text={t('dashboard.actions.risk.title')}
        description={t('dashboard.actions.risk.description')}
      />
    </div>
  );
}
