import { useState } from 'react';
import Typography from '../Typography/Typography';
import { arrayOf, bool, node, string } from 'prop-types';
import './Tab.scss';

const TabHeader = ({ tabIndex, isActive, onClick, title, fullWidthHeader }) => {
  const className = `tab-header  ${isActive ? 'active' : ''}`;
  const iconClass = `pi pi-angle-${isActive ? 'up' : 'down'}`;

  return (
    <div
      className={className}
      onClick={() => onClick(tabIndex)}
      style={{
        width: fullWidthHeader ? '50%' : '20%',
        justifyContent: !fullWidthHeader ? 'center' : 'space-between',
      }}
    >
      <Typography text={title} color='white' variant='h5' />
      {fullWidthHeader && <i className={iconClass}></i>}
    </div>
  );
};

const TabBody = ({ isActive, removePadding, children, ...other }) => {
  const className = `tab-body ${isActive ? 'bg-dark active' : ''} ${removePadding ? '' : 'p-4'}  h-full ${other.className}`;

  return isActive ? <div className={className}>{children}</div> : null;
};

const Tab = ({
  headers,
  bodyContents,
  fullWidthHeader,
  closable,
  ...other
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [closedTab, setClosedTab] = useState(false);
  const handleTabClick = (tabIndex) => {
    if (closable && tabIndex === activeTab && !closedTab) {
      setClosedTab(true);
      setActiveTab(null);
      return;
    }
    setClosedTab(false);
    setActiveTab(tabIndex);
  };
  return (
    <div className='tab-container w-full'>
      <div className='flex'>
        {headers.map((header, index) => (
          <TabHeader
            key={`${header}-${index}`}
            tabIndex={index}
            isActive={activeTab === index}
            onClick={handleTabClick}
            title={header}
            fullWidthHeader={fullWidthHeader}
          />
        ))}
      </div>
      {!closedTab && (
        <div {...other}>
          {bodyContents.map((Content, index) => (
            <TabBody
              key={index}
              className={`${!fullWidthHeader && 'tab-body-radius'} p-3`}
              tabIndex={index}
              isActive={activeTab === index}
              removePadding={activeTab === 0}
            >
              <Content />
            </TabBody>
          ))}
        </div>
      )}
    </div>
  );
};

Tab.propTypes = {
  headers: arrayOf(string.isRequired).isRequired,
  bodyContents: arrayOf(node.isRequired).isRequired,
  fullWidthHeader: bool,
  closable: bool,
};

export default Tab;
