import { Paginator } from 'primereact/paginator';
import './MainTable.scss';

const TablePaginator = ({
  first,
  rows,
  totalRecords,
  onPageChange,
  setFirst,
}) => (
  <Paginator
    first={first}
    rows={rows}
    totalRecords={totalRecords}
    onPageChange={(event) => {
      setFirst(event.first + 1);
      onPageChange(event.page + 1);
    }}
    template={{
      layout: 'PrevPageLink CurrentPageReport NextPageLink',
    }}
    className='body1 lowercase'
  />
);

export default TablePaginator;
