import PropTypes, { number, string } from 'prop-types';

const Image = ({ src, width, height, alt, customStyle, ...other }) => {
  return (
    <img
      src={src}
      style={{
        width,
        height,
        ...customStyle,
      }}
      alt={alt}
      {...other}
    />
  );
};

Image.propTypes = {
  src: string.isRequired,
  width: PropTypes.oneOfType([string, number]),
  height: number,
  alt: string,
  className: string,
};
export default Image;
