import React from 'react';
import Circle from '../Circle/Circle';
import Typography from '../Typography/Typography';
import Card from '../Card/Card';
import { COLORS } from '../../assets/constants/COLORS';

const { dark_gray, gray } = COLORS;

const RatingBar = ({ impact, effort }) => {
  const renderCircles = (value, classNames = '') => {
    return (
      <Card
        className={`${classNames} flex flex-row align-items-center`}
        autoDimensions
        customStyles={{ gap: '.15vw' }}
      >
        {Array.from({ length: 5 }, (_, i) => (
          <Circle key={i} color={i < value ? gray : dark_gray} size='x-small' />
        ))}
      </Card>
    );
  };

  return (
    <Card className='flex flex-column mt-3' autoDimensions>
      <Card className='flex flex-row' autoDimensions>
        <Typography
          text='IMPACT'
          variant='subtitle2'
          color='white'
          classNames='mr-2 '
        />
        {renderCircles(impact)}
      </Card>
      <Card className='flex flex-row' autoDimensions>
        <Typography
          text='EFFORT'
          variant='subtitle2'
          color='white'
          classNames='mr-2'
        />

        {renderCircles(effort, 'ml-1')}
      </Card>
    </Card>
  );
};

export default RatingBar;
