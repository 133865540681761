import { COLORS } from '../../../../../assets/constants/COLORS';

const { green, orange, red, yellow, gray_medium, dark_gray } = COLORS;

export const impactsProfile = [
  { title: 'Greenhouse gas emissions', color: red, esrs: 'e2' },
  { title: 'Non-greenhouse gas air pollutants', color: red, esrs: 'e1' },
  { title: 'Solid waste', color: orange, esrs: 'e3,e4' },
  { title: 'Soil pollution', color: green, esrs: 'e1,e2' },
  { title: 'Water pollution', color: orange, esrs: 'e2' },
  { title: 'Terrestrial ecosystem use', color: green, esrs: 'e2' },
  { title: 'Freshwater ecosystem use', color: red, esrs: 'e1' },
  { title: 'Marine ecosystem use', color: red, esrs: 'e3,e4' },
  { title: 'Water use', color: yellow, esrs: 'e1,e2' },
  { title: 'Other resource use', color: yellow, esrs: 'e2' },
  {
    title: 'Biological alterations interferences',
    color: dark_gray,
    fontColor: gray_medium,
  },
  { title: 'Disturbances', color: dark_gray, fontColor: gray_medium },
];
