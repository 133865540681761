import React from 'react';
import Typography from '../../../../components/Typography/Typography';
import TextField from '../../../../components/TextField/TextField';
import Button from '../../../../components/Button/Button';

const textFieldContainerStyle = {
  minHeight: '4.1vw',
  width: '100%',
};
const buttonWrapper = {
  width: '12.5vw',
  minHeight: '3vw',
};
const fields = [
  { label: 'Site name' },
  { label: 'Street address' },
  { label: 'City' },
  [{ label: ' Section ' }, { label: ' Division' }],
];
const ManualLocation = () => {
  return (
    <div className='w-full flex flex-column align-items-center gap-4'>
      <div className='w-full flex flex-column align-items-center gap-5 text-center'>
        <Typography text='Insert manually' variant='h4' color='white' />
        <div className='flex flex-column gap-3 h-full w-full'>
          {fields.map((field, index) =>
            Array.isArray(field) ? (
              <div
                key={index}
                className='flex gap-5'
                style={{ minHeight: textFieldContainerStyle.minHeight }}
              >
                {field.map((f) => (
                  <TextField
                    radiusType='semi-rounded'
                    label={f.label}
                    key={f.label}
                    variant='dark'
                  />
                ))}
              </div>
            ) : (
              <div key={field.label} style={textFieldContainerStyle}>
                <TextField
                  radiusType='semi-rounded'
                  label={field.label}
                  variant='dark'
                />
              </div>
            )
          )}
        </div>
      </div>
      <div style={buttonWrapper}>
        <Button variant='light'>save location</Button>
      </div>
    </div>
  );
};

export default ManualLocation;
