import { useNavigate } from 'react-router-dom';
import './Risk.scss';
import TableBody from '../../../components/MainTable/TableBody';

const RiskTableBody = ({ locations, onSort }) => {
  const navigate = useNavigate();

  if (!locations) {
    console.error('Locations prop is missing');
    return <div>Error: Locations data is not available</div>;
  }

  const mappedLocations = locations.map((location) => {
    const address = String(location.properties?.address);
    const words = address.split(/[, ]+/); // Split by comma or space
    const firstThreeWords = words.slice(0, 3).join(', '); // Get the first three words

    return {
      score: location.properties?.score ?? 'N/A',
      name: location.properties?.name ?? 'N/A',
      id: String(location.properties?.id).substring(0, 20) ?? 'N/A',
      address: firstThreeWords ?? 'N/A',
      countryCode: location.properties?.countryCode ?? 'N/A',
      type: 'Own',
    };
  });

  const columns = [
    { field: 'score', header: 'Priority Score' },
    { field: 'name', header: 'Site Name' },
    { field: 'id', header: 'State Id' },
    { field: 'address', header: 'Address' },
    { field: 'countryCode', header: 'Country' },
    { field: 'type', header: 'Site Type' },
  ];

  const onRowClick = (event) => {
    if (!event.data.id) {
      console.warn('Location ID is missing in the clicked row data');
      return;
    }
    navigate(`/?location_id=${event.data.id}`);
  };

  const sortableFields = ['score', 'name', 'countryCode'];

  return (
    <TableBody
      columns={columns}
      rows={mappedLocations}
      onSort={onSort}
      onRowClick={onRowClick}
      sortableFields={sortableFields}
      specialColumns={['name']}
      initialSort={{
        field: 'score',
        order: -1,
      }}
    />
  );
};

export default RiskTableBody;
