import Card from '../../../../components/Card/Card';
import Typography from '../../../../components/Typography/Typography';
import styles from './LabelBox.module.scss';

const LabelBox = ({ text, description }) => {
  return (
    <Card bgColor='dark' radius='medium'>
      <div className={`${styles['label-box']} h-full`}>
        <Typography text={text} variant='h4' color='white' />
        <Typography text={description} variant='body1' color='gray-medium' />
      </div>
    </Card>
  );
};

export default LabelBox;
