/**
 * Translation Organization Guide
 *
 * This file organizes translations by page names and their respective components.
 * Each page in the application has its own set of translations, which are further
 * divided by the components within that page.
 *
 * Structure:
 * pageName: {
 *   componentName: "translation",
 *   anotherComponentName: "translation",
 * }
 *
 * * Example:
 * homePage: {
 *   welcomeMessage: "Welcome to our application!",
 *   logoutButton: "Log out",
 * },
 * loginPage: {
 *   usernameField: "Username",
 *   submitButton: "Submit",
 * }
 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-GB',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;
