import { useState } from 'react';
import Button from '../../../components/Button/Button';
import TextField from '../../../components/TextField/TextField';
import Dropdown from '../../../components/Dropdown/Dropdown';
import './Risk.scss';

const RiskTableHeader = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState(null);

  const onSearchChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/\s+/g, ' ');

    const sq = { ...searchQuery, [name]: trimmedValue };
    setSearchQuery(sq);
    onSearch(sq);
  };

  const items = [
    { id: 1, text: 'Nature risk' },
    { id: 2, text: 'Climate risk' },
    { id: 3, text: 'Deforestation risk' },
  ];

  return (
    <div className='risk-table-header'>
      <div className='risk-dropdwon'>
        <Dropdown items={items} />
      </div>
      <div className='risk-textfield'>
        <TextField
          name='name'
          value={searchQuery?.['name']}
          placeholder='Search'
          type='text'
          onChange={onSearchChange}
          variant='light'
          icon='pi pi-search'
          iconPosition='right'
        />
      </div>
      <div className='risk-download-list-btn'>
        <Button>Download list</Button>
      </div>
    </div>
  );
};

export default RiskTableHeader;
