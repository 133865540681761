import RadioButton from '../../../../../components/RadioButton/RadioButton';
import TextField from '../../../../../components/TextField/TextField';
import Typography from '../../../../../components/Typography/Typography';
import WhiteCheckBox from '../../../../../assets/icons/WhiteCheckbox';
import DisabledCheckBox from '../../../../../assets/icons/DisabledCheckbox';
import data from './data.json';
import PrimaryTable from '../../../../../components/GenericTable';

const halfWidthStyles = {
  width: '50%',
};
const keystoneSpeciesStyles = {
  width: '55%',
  height: '2.6vw',
};
const headers = [
  {
    key: 'species',
    title: 'species',
    columnStyles: 'pl-5',
    render: (data) => (
      <Typography color='white' text={data} variant='body1' classNames='px-3' />
    ),
  },
  {
    key: 'keystoneSpecies',
    title: 'keystone species',
    render: (data) => (
      <Typography
        color='white'
        text={data}
        variant='body1'
        classNames='px-3 ml-5'
      />
    ),
  },
  {
    key: 'protected',
    title: 'protected',
    cellAlignment: 'center',

    render: (props) => (
      <div className='flex ' style={halfWidthStyles}>
        {props ? <WhiteCheckBox /> : <DisabledCheckBox />}
      </div>
    ),
  },
  {
    key: 'threatened',
    title: 'threatened',
    cellAlignment: 'center',
    render: (props) => (
      <div className='flex ' style={halfWidthStyles}>
        {props ? <WhiteCheckBox /> : <DisabledCheckBox />}
      </div>
    ),
  },
];

const Species = () => {
  const options = ['Threatened', 'Protected'];
  return (
    <div className='flex flex-column gap-3'>
      <div
        className=' flex gap-2 grid-nogutter mt-2 ml-5'
        style={keystoneSpeciesStyles}
      >
        <div>
          <TextField
            name=''
            placeholder='Keystone species'
            icon='pi pi-search'
            variant='dark'
            type='text'
            iconPosition='right'
          />
        </div>
        <div>
          <RadioButton
            options={options}
            variant='dark'
            extraClassNames='align-items-center justify-content-around'
          />
        </div>
      </div>
      <PrimaryTable data={data} headers={headers} />
    </div>
  );
};

export default Species;
