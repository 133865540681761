import React from 'react';
import PropTypes from 'prop-types';
import styles from './GenericTable.module.scss';
import Typography from '../Typography/Typography';

const GenericTable = ({ headers, data }) => {
  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableHeader}></div>
      <table className={styles.tableWrapper}>
        <thead>
          <tr>
            {headers?.map((header, index) => (
              <th
                key={`${header.title}-${index}`}
                align={header.textAlign || 'left'}
                className={header?.columnStyles || ''}
              >
                <Typography
                  key={header.title}
                  color='white'
                  text={header.title}
                  format='uppercase'
                  variant='h6'
                />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((column) => renderTableCell(column, row))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
const renderTableCell = (column, row) => {
  return (
    <>
      <td align={column.cellAlignment || 'left'} className={column?.cellStyles}>
        <div>{column.render(row[column.key])}</div>
      </td>
    </>
  );
};

GenericTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool]))
  ).isRequired,
};

export default GenericTable;
