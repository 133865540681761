import locationJson from '../locations.json';
import { haversineDistance } from './haversineDistance';

export const generateProfiles = () => {
  const profileData = {};
  const first5Locations = locationJson.features.slice(0, 5);

  first5Locations.forEach((location) => {
    const country = location.properties.countryCode;

    if (!profileData[country]) {
      profileData[country] = {
        impactProfile: location.properties.impactProfile,
        dependencyProfile: location.properties.dependencyProfile,
        natureRiskProfile: location.properties.natureRiskProfile,
        climateProfile: location.properties.climateProfile,
      };
    }
  });

  return profileData;
};

export function generateLocations({ radius, latitude, longitude }, query) {
  if (!query) {
    const locations = locationJson.features.slice(9500);
    const filtered = locations.filter(
      (location) =>
        haversineDistance(
          [longitude, latitude],
          location.geometry.coordinates
        ) <= radius
    );

    return filtered;
  } else {
    const {
      page: p,
      pageSize: pz,
      field: sortField,
      order: sortOrder,
      ...searchQuery
    } = query;

    const page = parseInt(p) || 1;
    const pageSize = parseInt(pz) || 20000;

    const offset = (page - 1) * pageSize;

    let filteredLocations = locationJson.features;

    if (Object.keys(searchQuery).length > 0) {
      filteredLocations = filteredLocations.filter((location) => {
        return Object.entries(searchQuery).every(([key, value]) => {
          return String(location.properties[key])
            .toLowerCase()
            ?.includes(String(value).toLowerCase());
        });
      });
    }

    if (sortField) {
      filteredLocations.sort((a, b) => {
        if (a.properties[sortField] < b.properties[sortField]) {
          return -1 * sortOrder;
        }
        if (a.properties[sortField] > b.properties[sortField]) {
          return 1 * sortOrder;
        }
        return 0;
      });
    }

    const paginatedLocations = filteredLocations.slice(
      offset,
      offset + pageSize
    );

    return {
      features: paginatedLocations,
      page,
      pageSize,
      total: filteredLocations.length,
    };
  }
}
