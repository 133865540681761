import React from 'react';

const WhiteCheckbox = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 4C0 1.79086 1.79086 0 4 0H21C23.2091 0 25 1.79086 25 4V21C25 23.2091 23.2091 25 21 25H4C1.79086 25 0 23.2091 0 21V4Z'
        fill='white'
      />
      <path
        d='M6.00026 12.1086L10.8931 17.002L20.0015 7.89355'
        stroke='#191919'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default WhiteCheckbox;
