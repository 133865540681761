import classes from './Card.module.scss';
import PropTypes from 'prop-types';

// Helper function to map specific corners to CSS class names
const getBorderRadiusClasses = (radius, specificCorners) => {
  const cornerClassMap = {
    tl: `card-border-top-left-${radius}`,
    tr: `card-border-top-right-${radius}`,
    bl: `card-border-bottom-left-${radius}`,
    br: `card-border-bottom-right-${radius}`,
  };

  // If no specificCorners are provided, apply to all corners
  if (!specificCorners || specificCorners.length === 0) {
    return Object.values(cornerClassMap).map(
      (cornerClass) => classes[cornerClass]
    );
  }

  // Apply to the specified corners only
  return specificCorners.map((corner) => classes[cornerClassMap[corner]]);
};

const Card = ({
  children,
  radius,
  specificCorners,
  bgColor,
  className,
  autoDimensions,
  customStyles = {},
}) => {
  const borderRadiusClasses = getBorderRadiusClasses(radius, specificCorners);

  const combinedClasses = [
    'card',
    autoDimensions ? '' : 'w-full h-full',
    ...borderRadiusClasses,
    classes[`card-bg-${bgColor}`],
    className,
  ].join(' ');

  return (
    <div className={combinedClasses} style={customStyles}>
      {children}
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  radius: PropTypes.oneOf(['large', 'medium', 'small']).isRequired,
  specificCorners: PropTypes.arrayOf(PropTypes.oneOf(['tl', 'tr', 'bl', 'br'])),
  bgColor: PropTypes.oneOf([
    'darkest',
    'dark',
    'gray-opacity-dark',
    'gray-dark',
    'gray-medium',
    'gray-light',
    'white',
  ]),
  className: PropTypes.string,
  autoDimensions: PropTypes.bool,
  customStyles: PropTypes.object,
};

export default Card;
