import React from 'react';

const ArableLand = () => {
  return (
    <svg
      width='21'
      height='11'
      viewBox='0 0 21 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_634_1212)'>
        <path
          d='M8.63234 5.68687C9.04375 4.9794 9.4963 4.31482 10.0311 3.6931C8.17979 2.21386 6.04047 1.18482 3.7983 0.670302C3.16062 0.520234 2.4818 0.563111 1.88525 0.820371C2.15267 0.927562 2.44065 1.03475 2.70807 1.16338C4.99138 2.21386 7.00728 3.75742 8.63234 5.68687Z'
          fill='white'
        />
        <path
          d='M10.3394 3.3503C10.8742 2.77146 11.4502 2.25694 12.0879 1.80674C9.68116 0.241742 6.76016 -0.35853 3.90088 0.220304C4.86769 0.434687 5.8345 0.756262 6.76016 1.16359C8.03553 1.76386 9.24918 2.49277 10.3394 3.3503Z'
          fill='white'
        />
        <path
          d='M1.9674 1.39921C1.47371 1.20626 0.938877 1.24914 0.486328 1.5064V9.15987C0.486328 10.1889 1.28857 11.0036 2.25538 11.0036H7.00714C7.13056 9.26707 7.62425 7.61632 8.38536 6.11563C6.63687 3.99324 4.43584 2.38537 1.9674 1.39921Z'
          fill='white'
        />
        <path
          d='M16.5518 0.242188C11.5944 1.27123 7.80942 5.64464 7.37744 11.0042H9.78418C10.4013 7.70272 11.9235 4.67992 14.248 2.23595C14.9679 1.50705 15.729 0.84246 16.5518 0.242188Z'
          fill='white'
        />
        <path
          d='M20.0283 0.0706524C19.5963 0.0277757 19.1438 0.177844 18.8146 0.499419C16.0376 3.17921 14.1863 6.8666 13.7749 11.0042H18.2592C19.2466 11.0042 20.0283 10.1681 20.0283 9.1605V0.0706524Z'
          fill='white'
        />
        <path
          d='M18.6501 0.00390625C17.9919 0.00390625 17.3542 0.239728 16.8193 0.625617C13.4869 3.02671 11.0596 6.7141 10.2368 11.0018H13.3429C13.6926 7.07855 15.3383 3.45548 18.033 0.625617C18.2181 0.432673 18.4444 0.218289 18.6501 0.00390625Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_634_1212'>
          <rect
            width='19.5419'
            height='11'
            fill='white'
            transform='translate(0.486328 0.00390625)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArableLand;
