import React, { useState } from 'react';
import Card from '../../../../components/Card/Card';
import { SelectButton } from '../../../../components/SelectButton/SelectButton';
import Circle from '../../../../components/Circle/Circle';
import Typography from '../../../../components/Typography/Typography';
import Tab from '../../../../components/Tab/Tab';
import NatureRisk from './Risk/NatureRisk';
import ClimateRisk from './Risk/ClimateRisk';
import DeforestationRisk from './Risk/DeforestationRisk';
import NatureProfile from './Profiles/NatureProfile';
import MaterialityProfile from './Profiles/MaterialityProfile';
import { COLORS } from '../../../../assets/constants/COLORS';
import { status } from '../../../../constants';

const ProfileAndRisk = () => {
  const { green, orange, red, yellow } = COLORS;
  const colors = [red, orange, yellow, green];
  const options = ['Profiles', 'Risk'];
  const [value, setValue] = useState(options[0]);

  const tabHeaders = {
    [options[0]]: ['Materiality', 'Nature'],
    [options[1]]: ['Nature Risk', 'Climate Risk', 'Deforestation Risk'],
  };

  const tabBodies = {
    [options[0]]: [MaterialityProfile, NatureProfile],
    [options[1]]: [NatureRisk, ClimateRisk, DeforestationRisk],
  };

  return (
    <Card bgColor='gray-light' radius='large'>
      <div className='p-3 flex flex-column gap-4'>
        <div className='w-full flex justify-content-between'>
          <Card className='bg-white' radius='medium' autoDimensions>
            <SelectButton
              returnValue={(value) => {
                setValue(value);
              }}
              options={options}
            />
          </Card>

          <div className='col-4 flex justify-content-around gap-5'>
            {status.map((labelKey, index) => (
              <div key={labelKey} className='flex align-items-center gap-1'>
                <Circle size='small' color={colors[index]} />
                <Typography
                  text={`dashboard.profiles.labels.${labelKey}`}
                  variant='body1'
                  color='dark'
                />
              </div>
            ))}
          </div>
        </div>

        <Tab
          style={{ height: '67vh' }}
          headers={tabHeaders[value]}
          bodyContents={tabBodies[value]}
        />
      </div>
    </Card>
  );
};

export default ProfileAndRisk;
