import React from 'react';
import Card from '../../../../../components/Card/Card';
import Typography from '../../../../../components/Typography/Typography';
import Circle from '../../../../../components/Circle/Circle';
import { COLORS } from '../../../../../assets/constants/COLORS';
import './Profiles.scss';

const MaterialityProfile = () => {
  const { green, orange, red, yellow, gray_medium, dark_gray } = COLORS;

  const impactsProfile = [
    { title: 'Greenhouse gas emissions', color: red, esrs: 'e2' },
    { title: 'Non-greenhouse gas air pollutants', color: red, esrs: 'e1' },
    { title: 'Solid waste', color: orange, esrs: 'e3,e4' },
    { title: 'Soil pollution', color: green, esrs: 'e1,e2' },
    { title: 'Water pollution', color: orange, esrs: 'e2' },
    { title: 'Terrestrial ecosystem use', color: green, esrs: 'e2' },
    { title: 'Freshwater ecosystem use', color: red, esrs: 'e1' },
    { title: 'Marine ecosystem use', color: red, esrs: 'e3,e4' },
    { title: 'Water use', color: yellow, esrs: 'e1,e2' },
    { title: 'Other resource use', color: yellow, esrs: 'e2' },
    {
      title: 'Biological alterations interferences',
      color: dark_gray,
      fontColor: gray_medium,
    },
    { title: 'Disturbances', color: dark_gray, fontColor: gray_medium },
  ];

  const renderProfileCard = (profile, index, isLast) => (
    <div>
      <Card
        bgColor={index % 2 === 0 ? 'dark' : 'gray-dark'}
        className={`flex justify-content-between align-items-center p-2 ${isLast && 'border-round-bottom-xl'}`}
      >
        <div className='flex gap-2 align-items-center'>
          <Circle size='small' color={profile.color} />
          <Typography
            color={profile?.fontColor || 'white'}
            text={profile.title}
            variant='body1'
          />
        </div>
        <Typography color='white' text={profile.esrs} variant='body1' />
      </Card>
    </div>
  );

  return (
    <div className='flex gap-2'>
      <div className='p-0 w-33'>
        <div>
          <Card
            bgColor='white'
            className='flex justify-content-between border-round-top-xl p-2'
          >
            <Typography
              format='uppercase'
              color='dark'
              text='Impacts'
              variant='h5'
            />
            <Typography color='dark' text='ESR' variant='h5' />
          </Card>
        </div>
        {impactsProfile.map((profile, index) =>
          renderProfileCard(profile, index, index === impactsProfile.length - 1)
        )}
      </div>

      <div className=' p-0 w-66'>
        <div>
          <Card
            bgColor='white'
            className='grid-nogutter flex justify-content-between border-round-top-xl p-2'
          >
            <div className='col-6 flex justify-content-between'>
              <Typography
                format='uppercase'
                color='dark'
                text='Dependencies'
                variant='h5'
              />
              <div className='mx-2'>
                <Typography color='dark' text='ESR' variant='h5' />
              </div>
            </div>
          </Card>
        </div>
        <div className='grid-nogutter flex'>
          <div className='col'>
            {impactsProfile.map((profile, index) =>
              renderProfileCard(
                profile,
                index + 1,
                index === impactsProfile.length - 1
              )
            )}
          </div>
          <div className='col'>
            {impactsProfile
              .slice(0, 7)
              .map((profile, index) =>
                renderProfileCard(
                  profile,
                  index,
                  index === impactsProfile.length - 1
                )
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaterialityProfile;
