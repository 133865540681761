import React from 'react';

const DisabledCheckbox = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='25' height='25' rx='4' fill='#777777' />
    </svg>
  );
};

export default DisabledCheckbox;
