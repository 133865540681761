import mainLogo from '../../assets/kuyuaLogo.png';
import whiteLogo from '../../assets/kuyuaLogo-white.png';
import clientLogo from '../../assets/clientLogo.png';
import clientProfileLogo from '../../assets/client-profile-logo.png';
import { NavLink, useLocation } from 'react-router-dom';
import Navbar from '../Nav/NavigationBar';
import Image from '../Image/Image';
import './Header.scss';
import { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import Typography from '../Typography/Typography';
import TextField from '../TextField/TextField';
import Button from '../Button/Button';
import Camera from '../../assets/icons/Camera';
import IconButton from '../IconButton/IconButton';

export default function Header() {
  const [visibleRight, setVisibleRight] = useState(false);
  const { pathname } = useLocation();
  const isDarkModeOn = pathname.includes('actions');
  return (
    <header
      className={`w-full h-full grid-nogutter flex justify-content-between align-items-center`}
    >
      <div className='col-3'>
        <NavLink to='/'>
          <Image
            src={isDarkModeOn ? whiteLogo : mainLogo}
            width='100%'
            height='100%'
            className='p-image p-2 center'
            customStyle={{ maxWidth: '15.75vw', maxHeight: '4.7vw' }}
          />
        </NavLink>
      </div>

      <div className='col-8 mt-2'>
        <Navbar />
      </div>

      <div
        className='flex justify-content-end align-items-center h-full cursor-pointer'
        onClick={() => setVisibleRight(true)}
      >
        <Image height='3.8vw' src={clientLogo} />
      </div>

      <Sidebar
        visible={visibleRight}
        position='right'
        className='sidebar'
        onHide={() => setVisibleRight(false)}
        closeIcon={
          <IconButton icon='icon pi pi-times text-white border-circle p-3 text-sm' />
        }
      >
        <div className='review-wrapper'>
          <div className='w-full flex flex-column align-items-center justify-content-center gap-3'>
            <div className='relative'>
              <Image
                width='11.2vw'
                height='11.2vw'
                src={clientProfileLogo}
                className='p-image'
              />
              <div className='camera-icon'>
                <Camera />
              </div>
            </div>
            <Typography text='REWE Center' variant='h3' color='dark' />
            <Typography
              text='Business subscription plan'
              variant='body1-lg'
              color='secondary'
            />
          </div>
          <div className='flex flex-column gap-3'>
            <TextField
              variant='light'
              label='E-Mail'
              name='email'
              type='email'
              className='py-1'
            />
            <TextField
              variant='light'
              label='Change Password'
              name='password'
              type='password'
              className='py-1'
            />
            <TextField
              variant='light'
              label='Add Account'
              name='text'
              className='py-1'
              type='text'
            />

            <TextField
              variant='light'
              label='List of accounts'
              name='text'
              type='select'
              className='py-1'
              options={[
                { label: 'Rewe Center, Valencia 15, 95326, Spain', value: 1 },
              ]}
            />
          </div>
          <div className='flex w-full justify-content-end mt-2'>
            <Button className='w-12rem' size='small' color='dark'>
              Save
            </Button>
          </div>
        </div>
      </Sidebar>
    </header>
  );
}
