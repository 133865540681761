import ActionPlans from './components/ActionPlans/ActionPlans';
import Summary from './components/Summary/Summary';
import styles from './Actions.module.scss';
import MitigationTasks from './components/MitigationTasks/MitigationTasks';
import MinimizeSoil from './components/MinimizeSoil/MinimizeSoil';

export default function Actions() {
  return (
    <section className={styles['actions-details']}>
      <div className={styles['grid-container']}>
        <div className={styles['summary-column']}>
          <Summary />
        </div>
        <div className={styles['plans-column']}>
          <ActionPlans />
        </div>
      </div>
      <div className={styles['grid-container']}>
        <MitigationTasks />
        <MinimizeSoil />
      </div>
    </section>
  );
}
