import React from 'react';
import classes from './IconButton.module.scss';
import { classNames } from 'primereact/utils';

const IconButton = ({ icon, variant, onClick }) => {
  const buttonVariant = variant || 'dark';
  const combinedClasses = classNames(
    icon,
    classes[buttonVariant + '-icon-button']
  );
  const IconComponent = () => {
    if (typeof icon === 'string') {
      return <i onClick={onClick} className={combinedClasses}></i>;
    }
    return icon;
  };
  return <IconComponent />;
};

export default IconButton;
