import { NavLink, matchPath, useLocation } from 'react-router-dom';
import Card from '../Card/Card';
import Typography from '../Typography/Typography';
import Button from '../Button/Button';
import classes from './Nav.module.scss';
import { classNames } from 'primereact/utils';

export default function Navbar() {
  const items = [
    {
      label: 'Dashboard',
      path: '/',
    },
    {
      label: 'Locations',
      path: '/locations',
    },
    {
      label: 'Location Details',
      path: '/location-details',
    },
    {
      label: 'Risk',
      path: '/risk',
    },
    {
      label: 'Report',
      path: '/report',
    },
    {
      label: 'Actions',
      path: '/actions',
    },
  ];

  const { pathname } = useLocation();
  const isDarkModeOn = pathname.includes('actions');

  const navItems = items.map((item) => {
    const isActive = !!matchPath(window.location.pathname, item.path);
    const containerClasses = classNames(classes.navbar, 'col p-0 h-full');
    const currentNavLinkTextColor = isActive
      ? isDarkModeOn
        ? 'black-darkest'
        : 'white'
      : isDarkModeOn
        ? 'white'
        : 'black-dark';

    return (
      <div className={containerClasses} key={`${item.label}-${item.path}`}>
        <Button
          variant={!isActive ? 'transparent' : isDarkModeOn ? 'light' : 'dark'}
        >
          <NavLink
            className={`${classes['navlink-item']} h-full `}
            to={item.path}
          >
            <Typography
              text={item.label}
              variant='body1'
              color={currentNavLinkTextColor}
            />
          </NavLink>
        </Button>
      </div>
    );
  });

  return (
    <Card radius='large' bgColor={isDarkModeOn ? 'gray-dark' : 'gray-light'}>
      <div
        className={`${classes['navbar-wrapper']} w-full grid py-1 mx-1 pr-2`}
      >
        {navItems}
      </div>
    </Card>
  );
}
