import { useState } from 'react';

import UploadLocation from './UploadLocation';
import Card from '../../../../components/Card/Card';
import Typography from '../../../../components/Typography/Typography';
import Button from '../../../../components/Button/Button';
import ManualLocation from './ManualLocation';
import IconButton from '../../../../components/IconButton/IconButton';

const cardStyles = {
  width: '45.4vw',
};

const insertManuallyButtonStyles = {
  width: '20.9vw',
  minHeight: '3.3vw',
};
const addLocationStyles = {
  position: 'absolute',
  top: 0,
};
const AddLocation = () => {
  const [locationAddType, setLocationAddType] = useState('upload');

  return (
    <div className='w-full flex justify-content-center'>
      <div
        className=' flex flex-column justify-content-center'
        style={cardStyles}
      >
        <Card radius='medium' className='relative' bgColor='dark'>
          <div
            style={addLocationStyles}
            className='w-full p-2 flex justify-content-between'
          >
            <div>
              {locationAddType === 'manual' && (
                <IconButton
                  onClick={() => {
                    setLocationAddType('upload');
                  }}
                  icon='pi pi-chevron-left'
                  variant='light'
                />
              )}
            </div>
            <div>
              <IconButton icon='pi pi-times' variant='light' />
            </div>
          </div>
          <div
            className={`h-full flex flex-column align-items-center gap-2 px-5 ${locationAddType === 'upload' ? 'py-6' : 'py-4'}`}
          >
            {locationAddType === 'upload' ? (
              <UploadLocation />
            ) : (
              <ManualLocation />
            )}
            {locationAddType === 'upload' && (
              <>
                <div>
                  <Typography text='or' color='white' variant='body1' />
                </div>
                <div style={insertManuallyButtonStyles}>
                  <Button
                    variant='light'
                    size='large'
                    onClick={() => {
                      setLocationAddType('manual');
                    }}
                  >
                    Insert Manually
                  </Button>
                </div>
              </>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AddLocation;
