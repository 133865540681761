import React, { useState } from 'react';
import { classNames } from 'primereact/utils';
import SelectionOn from '../../assets/icons/SelectionOn';
import SelectionOff from '../../assets/icons/SelectionOff';
import Typography from '../Typography/Typography';
import Card from '../Card/Card';

const RadioButton = ({
  options,
  cardBgColor,
  variant = 'light',
  value,
  title,
  extraClassNames,
}) => {
  const [selectedOption, setSelectedOption] = useState(value || options[0]);

  const onOptionChange = (option) => {
    setSelectedOption(option);
  };

  const Text = ({ text }) => (
    <Typography
      variant='body1'
      color={variant === 'light' ? 'black-dark' : 'white'}
      text={text}
    />
  );

  const Wrapper = ({ children }) => {
    return (
      <Card
        radius='large'
        bgColor={
          cardBgColor
            ? cardBgColor
            : variant === 'light'
              ? 'gray-light'
              : 'gray-dark'
        }
      >
        {children}
      </Card>
    );
  };
  const radios = options.map((option) => (
    <span
      key={option}
      className={classNames(
        'flex',
        'align-items-center',
        'gap-2',
        'cursor-pointer'
      )}
      onClick={() => {
        onOptionChange(option);
      }}
    >
      {selectedOption === option ? <SelectionOn /> : <SelectionOff />}
      <Text text={option} />
    </span>
  ));

  return (
    <Wrapper>
      <div className={`flex h-full w-full px-3 ${extraClassNames}`}>
        {title && <Text text={title + ' :'} />}
        <div className='flex gap-5'>{radios}</div>
      </div>
    </Wrapper>
  );
};

export default RadioButton;
