import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapTab from './MapTab';
import Card from '../../../../components/Card/Card';
import styles from './Map.module.scss';

const Map = () => {
  const mapContainerRef = useRef();
  const mapRef = useRef();

  useEffect(() => {
    mapboxgl.accessToken =
      'pk.eyJ1IjoiYWhtZWRtYWdkeTc3NzciLCJhIjoiY2x5N3Q3aWc1MDNtODJrc2ZoNWIyamEzZSJ9.na5s7DpbwC5E6bAQofF40g';

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/satellite-streets-v12',
      center: [13.811, -4.391],
      zoom: 12,
    });

    mapRef.current.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        countries: 'au',
        bbox: [139.965, -38.03, 155.258, -27.839],
        filter: function (item) {
          return item.context.some((i) => {
            return (
              i.id.split('.').shift() === 'region' &&
              i.text === 'New South Wales'
            );
          });
        },
        mapboxgl: mapboxgl,
      })
    );

    return () => {
      mapRef.current.remove();
    };
  }, []);

  return (
    <Card radius='large' bgColor='white'>
      <div ref={mapContainerRef} className={styles['map-tab-wrapper']}>
        <div className={styles['map-tab']}>
          <MapTab />
        </div>
      </div>
    </Card>
  );
};

export default Map;
