import React from 'react';
import styles from './Plans.module.scss';
import plans from './data.json';
import Typography from '../../../../../components/Typography/Typography';
import Card from '../../../../../components/Card/Card';

const Plans = () => {
  return (
    <div className={styles.cardContainer}>
      {plans?.data?.map((plan) => (
        <Card
          key={plan.title}
          bgColor={plan.background}
          radius='medium'
          className='flex flex-column align-items-center text-center justify-content-center '
        >
          <Typography
            variant='body1'
            color={plan.fontColor}
            text={plan.title}
            dangerHtml
          />
          <Typography
            variant='h1'
            color={plan.fontColor}
            text={plan.plansNumber}
            classNames='py-6'
          />

          <Typography
            variant='body1'
            color={plan.fontColor}
            text={plan.description}
          />
        </Card>
      ))}
    </div>
  );
};

export default Plans;
