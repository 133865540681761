import React from 'react';

const SelectionOn = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12.5' cy='12.5' r='12.5' fill='white' />
      <circle cx='12.5' cy='12.5' r='7.5' fill='#191919' />
    </svg>
  );
};

export default SelectionOn;
