import React from 'react';
import Card from '../../../../../components/Card/Card';
import Typography from '../../../../../components/Typography/Typography';
import Circle from '../../../../../components/Circle/Circle';
import {
  natureConditions,
  natureExtendsAndFragmentation,
  natureRelatedRisks,
  natureResiliences,
} from './data';
import './Profiles.scss';

const NatureProfile = () => {
  const renderProfile = (profiles, title, fillOdd) => {
    const fillOddItem = (index) => {
      if (fillOdd) {
        return (index + 1) % 2 !== 0;
      } else {
        return (index + 1) % 2 === 0;
      }
    };
    return (
      <div className='w-full'>
        <div>
          <Card
            bgColor='white'
            className='flex justify-content-between border-round-top-xl p-2'
          >
            <Typography
              format='uppercase'
              color='black-dark'
              text={title}
              variant='h5'
            />
          </Card>
        </div>
        {profiles.map((profile, index) => (
          <div key={`${profile.title}-${index}`}>
            <Card
              key={index}
              bgColor={fillOddItem(index) ? 'gray-dark' : 'dark'}
              className={`${index === profiles.length - 1 && 'border-round-bottom-xl'}`}
            >
              <div
                style={{
                  ...(typeof profile.description === 'object'
                    ? { padding: '1vw 0' }
                    : { height: '3.8vw' }),
                }}
                className={`w-full text-white flex ${profile.description ? 'flex-column justify-content-center' : 'justify-content-between align-items-center'} gap-1 p-2 `}
              >
                <div className='flex gap-2 align-items-center'>
                  <Circle size='small' color={profile.color} />

                  <Typography
                    color='white'
                    text={profile.title}
                    variant='body1'
                    format='uppercase'
                  />

                  {profile.icon && 'ICON'}
                </div>
                {profile.description &&
                typeof profile.description === 'object' ? (
                  profile.description.map((desc, index) => (
                    <div
                      key={index}
                      className='flex justify-content-between text-xs mx-4'
                    >
                      <div className='flex align-items-center gap-2'>
                        {desc.icon}
                        <Typography
                          color='light'
                          text={desc.content}
                          variant='subtitle2'
                        />
                        {/* <small>{desc.content}</small> */}
                      </div>
                      <Typography
                        color='light'
                        text={desc.value}
                        variant='subtitle2'
                      />
                    </div>
                  ))
                ) : (
                  <div className='flex justify-content-between text-xs mx-4'>
                    <Typography
                      color='light'
                      text={profile.description}
                      variant='subtitle2'
                    />
                    <Typography
                      color='light'
                      text={profile.value}
                      variant='subtitle2'
                    />
                  </div>
                )}
              </div>
            </Card>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className='flex justify-content-between gap-2'>
      <div className='flex flex-column p-0 gap-4 w-33'>
        {renderProfile(natureConditions, 'nature condition', true)}
        {renderProfile(natureResiliences, 'nature Resilience', true)}
      </div>
      <div className='flex flex-column p-0 gap-3 w-33'>
        {renderProfile(
          natureExtendsAndFragmentation,
          'Nature Extend & Fragmentation'
        )}
      </div>
      <div className='flex flex-column p-0 gap-3 w-33'>
        {renderProfile(natureRelatedRisks, 'Nature-related Risks', true)}
      </div>
    </div>
  );
};

export default NatureProfile;
