import React from 'react';

const Pastureland = () => {
  return (
    <svg
      width='20'
      height='17'
      viewBox='0 0 20 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_634_1219)'>
        <path
          d='M11.0232 3.40513C10.3967 2.81334 9.27935 1.7579 8.96611 1.46201C9.07268 1.56267 8.72715 0.976991 9.99302 0.976991C10.9327 0.976991 10.7648 0.24794 10.7648 0.24794C10.7648 0.24794 9.64749 0.543831 8.70777 0.24794C7.76806 -0.0479509 6.99303 0.732957 6.99303 0.732957L6.13405 0.00390625C5.19433 0.595688 6.05009 1.46201 6.05009 1.46201H0.515137V8.74946H3.05334C3.28584 8.74946 3.59585 9.25888 3.60231 9.47851C3.6346 10.9305 3.34397 10.9366 3.31168 11.9066L3.67981 11.9738C3.98982 12.0012 4.30629 12.0348 4.63244 12.0775C4.64536 9.93913 5.29767 8.74946 5.88217 8.74946V12.1507L7.19971 12.3947C7.25783 11.9097 7.31273 11.7724 7.19971 11.6657C7.02748 11.503 6.9306 11.0729 6.90907 10.3753C6.89615 9.96353 7.16741 8.75251 7.16741 8.75251C8.19432 7.53845 7.93921 4.86628 7.93921 4.86628C7.93921 3.68271 9.21799 4.80222 9.7379 4.62224C10.0673 4.50938 11.2718 3.64306 11.0232 3.40818V3.40513Z'
          fill='white'
        />
        <path
          d='M18.5117 7.04154C18.0984 7.43199 17.2265 8.49964 16.1963 8.49964C15.6829 8.49964 15.36 8.49659 15.1694 8.2556C14.3976 7.28557 14.3234 6.55957 13.1124 6.55957C12.5537 6.55957 11.8271 7.63637 11.8271 7.77364C11.8271 7.92616 12.0048 8.01767 12.3406 8.01767C13.1124 8.01767 13.3675 8.01157 13.3675 8.49964C13.3675 8.73452 13.4967 9.9974 13.881 10.4428C14.6237 11.2999 15.0822 11.4006 15.5473 11.4128L15.05 12.1174C15.2695 12.09 15.4924 12.0656 15.7184 12.0473L16.0026 11.6477C17.7432 11.3853 17.995 10.2018 17.995 10.2018C18.7668 9.71676 18.5085 8.98771 18.5085 8.98771C19.3868 8.43558 18.8024 6.767 18.5085 7.04459L18.5117 7.04154Z'
          fill='white'
        />
        <path
          d='M19.0285 12.6328C15.6862 12.1477 13.3708 12.8768 11.0587 13.6028C13.3741 14.0878 13.8875 15.7899 13.8875 15.7899C10.542 13.1178 3.85742 12.1477 0.515137 12.3918V17.0071H19.0285V12.6358V12.6328Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_634_1219'>
          <rect
            width='18.5133'
            height='17'
            fill='white'
            transform='translate(0.515137 0.00390625)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Pastureland;
