import Typography from '../../../../components/Typography/Typography';
import Button from '../../../../components/Button/Button';

const buttonStyles = {
  width: '17.2vw',
  minHeight: '3.3vw',
};

const UploadLocation = () => {
  return (
    <div className='flex flex-column gap-6'>
      <div className='flex flex-column gap-2 text-center'>
        <Typography text='You have no locations' variant='h4' color='white' />
        <Typography
          text='To add a location upload a list or insert manually'
          variant='body1'
          color='white'
        />
      </div>

      <div className='h-full flex flex-column justify-content-between align-items-center gap-2'>
        <div style={buttonStyles}>
          <Button variant='light' size='large'>
            Upload List
          </Button>
        </div>
        <div>
          <Button variant='text-dark'>Download Format List</Button>
        </div>
      </div>
    </div>
  );
};

export default UploadLocation;
