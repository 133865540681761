// Typography.js
import React from 'react';
import PropTypes from 'prop-types';
import classes from './Typography.module.scss';
import { useTranslation } from 'react-i18next';

const Typography = ({ variant, color, text, dangerHtml, classNames }) => {
  const { t } = useTranslation();
  function getText() {
    if (t(text)) return t(text);
    else return text;
  }

  // Get the HTML tag for the variant

  // If the variant or color is not provided, use defaults
  const finalVariant = variant || 'body1';
  const finalColor = color || 'black-darkest';

  // Combine the classes based on variant and color
  const combinedClasses = [
    classes[finalVariant],
    classes[finalColor],
    classNames,
  ].join(' ');

  return dangerHtml ? (
    <text
      className={combinedClasses}
      dangerouslySetInnerHTML={{ __html: getText() }}
    />
  ) : (
    <text className={combinedClasses}>{getText()}</text>
  );
};

Typography.propTypes = {
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'body1',
    'subtitle2',
  ]),
  color: PropTypes.oneOf([
    'black-darkest',
    'black-dark',
    'gray-dark',
    'gray-medium',
    'gray-light',
    'white',
    'red',
    'orange',
    'yellow',
    'green',
    'black-dark-opacity',
  ]),
  text: PropTypes.string.isRequired,
  dangerHtml: PropTypes.bool, // Indicates whether to use dangerouslySetInnerHTML for text rendering (for HTML content)
};

Typography.defaultProps = {
  variant: 'body1',
  color: 'black-darkest',
  dangerHtml: false,
};

export default Typography;
