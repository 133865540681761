import MapDetails from './components/MapDetails/MapDetails';
import ProfileAndRisk from './components/ProfileAndRisk/ProfileAndRisk';
import Summary from './components/Summary/Summary';
import './LocationDetails.scss';

export default function LocationDetails() {
  return (
    <section className='location-details'>
      <div className='grid-container'>
        <div className='summary-column'>
          <Summary />
        </div>
        <div className='map-column'>
          <MapDetails />
        </div>
      </div>
      <div className='profile-risk'>
        <ProfileAndRisk />
      </div>
    </section>
  );
}
