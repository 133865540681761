import Map from '../Map/Map';
import Card from '../../../../components/Card/Card';
import Typography from '../../../../components/Typography/Typography';
import './MapDetails.scss';

const MapDetails = () => {
  return (
    <div className='h-full w-full relative'>
      <Map />

      <div className='map-details-wrapper'>
        <Card radius='medium' bgColor='gray-light'>
          <div className='total-species'>
            <div className='flex flex-column align-items-center gap-2'>
              <Typography
                format='uppercase'
                color='dark'
                text='total species'
                variant='body1'
              />
              <Typography color='dark' text='4856' variant='h4' />
            </div>

            <div className='flex flex-column align-items-center gap-2'>
              <Typography
                format='uppercase'
                color='dark'
                text='THREATENED'
                variant='body1'
              />
              <Typography color='red' text='895' variant='h4' />
            </div>

            <div className='flex flex-column align-items-center gap-2'>
              <Typography
                format='uppercase'
                color='dark'
                text='KEYSTONE SPECIES'
                variant='body1'
              />
              <Typography color='dark' text='94' variant='h4' />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default MapDetails;
