import React, { useEffect, useRef, useState } from 'react';
import styles from './MitigationsTasks.module.scss';
import Card from '../../../../components/Card/Card';
import Typography from '../../../../components/Typography/Typography';
import data from './data.json';
import { InputSwitch } from '../../../../components/Inputs/';

const MitigationTasks = () => {
  const [filters, setFilters] = useState({
    isAvoid: true,
    isMinimize: true,
    isRestore: false,
  });
  const [selectedCard, setSelectedCard] = useState(0);

  const itemsRef = useRef([]);
  const handleChange = (name, value) => {
    setFilters((prevValues) => ({ ...prevValues, [name]: value }));
  };
  useEffect(() => {
    const items = document.querySelectorAll(`.${styles.taskItem}`);
    itemsRef.current = items;
  }, []);

  const handleClickCard = (index) => {
    setSelectedCard(+index);
    // removing styles from the card above the active card
    const currentPreviousCard = itemsRef?.current[index - 1];
    if (currentPreviousCard) currentPreviousCard.style.border = 'none';
    // after changing the selected card we rollback  styles to the card above the old active card
    const oldPreviousCard = itemsRef?.current[selectedCard - 1];
    if (oldPreviousCard) oldPreviousCard.style.borderBottom = '2px solid #fff';
  };
  return (
    <Card
      autoDimensions
      bgColor='dark'
      radius='large'
      className={styles.container}
    >
      <Typography
        classNames='pl-4 pb-3 '
        variant='body1'
        text='Select Mitigation Hierarchy Levels:'
        color='white'
      />
      <div className={styles.filters}>
        <InputSwitch
          label='Avoid'
          checked={filters.isAvoid}
          inputId='avoid'
          handleChange={(value) => handleChange('isAvoid', value)}
        />
        <InputSwitch
          label='Minimize'
          checked={filters.isMinimize}
          inputId='minimize'
          handleChange={(value) => handleChange('isMinimize', value)}
        />
        <InputSwitch
          label='Restore'
          checked={filters.isRestore}
          inputId='restore'
          handleChange={(value) => handleChange('isRestore', value)}
        />
      </div>
      <Typography
        classNames='px-4 pt-6 mb-4'
        variant='h3'
        text='TASKS:'
        color='white'
      />
      <div className={styles.tasksWrapper}>
        {data?.tasks.map((task, index) => {
          const isSelected = selectedCard === index;
          const currentColor = isSelected ? 'black-dark' : 'white';
          return (
            <div
              key={index}
              className={`${styles.taskItem} ${isSelected ? styles.selectedTask : ''}`}
              onClick={() => handleClickCard(index)}
            >
              <Typography
                variant='h4'
                text={task.title}
                color={currentColor}
                classNames='line-height-1 '
              />
              <Typography
                variant='subtitle1'
                classNames={`${styles.subtitle} ${isSelected ? styles.subtitleSelected : ''}`}
                text={`Tasks completed ${task.completed}/10`}
              />
              <Typography
                variant='body1'
                color={currentColor}
                text={`Impacts addressed: ${task.impacts}`}
              />
              <Typography
                variant='body1'
                color={currentColor}
                classNames='pb-1 pt-0'
                text={`Dependencies addressed: ${task.dependencies}`}
              />
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default MitigationTasks;
