import React from 'react';
import Card from '../../../../../components/Card/Card';
import Typography from '../../../../../components/Typography/Typography';
import Circle from '../../../../../components/Circle/Circle';
import { impactsProfile } from './data';
import '../Profiles/Profiles.scss';

const NatureRisk = () => {
  const renderProfileCard = (profile, index, isLast) => (
    <div>
      <Card
        bgColor={index % 2 === 0 ? 'dark' : 'gray-dark'}
        className={`flex justify-content-between align-items-center p-2 ${isLast && 'border-round-bottom-xl'}`}
      >
        <div className='flex gap-2 align-items-center'>
          <Circle size='small' color={profile.color} />
          <Typography
            color={profile?.fontColor || 'white'}
            text={profile.title}
            variant='body1'
          />
        </div>
        <Typography color='white' text={profile.esrs} variant='body1' />
      </Card>
    </div>
  );

  return (
    <div className='flex gap-2'>
      <div className='p-0 w-33'>
        <div>
          <Card
            bgColor='white'
            className='flex justify-content-between border-round-top-xl p-2'
          >
            <Typography
              format='uppercase'
              color='dark'
              text='Impacts'
              variant='h5'
            />
            <Typography color='dark' text='ESR' variant='h5' />
          </Card>
        </div>
        {impactsProfile.map((profile, index) =>
          renderProfileCard(profile, index, index === impactsProfile.length - 1)
        )}
      </div>

      <div className=' p-0 w-66'>
        <div>
          <Card
            bgColor='white'
            className='grid-nogutter flex justify-content-between border-round-top-xl p-2'
          >
            <div className='col-6 flex justify-content-between'>
              <Typography
                format='uppercase'
                color='dark'
                text='Dependencies'
                variant='h5'
              />
              <div className='mx-2'>
                <Typography color='dark' text='ESR' variant='h5' />
              </div>
            </div>
          </Card>
        </div>
        <div className='grid-nogutter flex'>
          <div className='col'>
            {impactsProfile.map((profile, index) =>
              renderProfileCard(
                profile,
                index + 1,
                index === impactsProfile.length - 1
              )
            )}
          </div>
          <div className='col'>
            {impactsProfile
              .slice(0, 7)
              .map((profile, index) =>
                renderProfileCard(
                  profile,
                  index,
                  index === impactsProfile.length - 1
                )
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NatureRisk;
