import Card from '../../../../components/Card/Card';
import Typography from '../../../../components/Typography/Typography';
import Plans from './Plans/Plans';
import './ActionPlans.scss';

const ActionPlans = () => {
  return (
    <Card bgColor='green' className='flex' radius='medium'>
      <div className='p-3 actions-details '>
        <Typography
          color='black'
          text='You have 12 new action plans'
          variant='h2'
          classNames='line-height-1 px-2 py-2'
        />
        <Typography
          color='black'
          text='Your priority score is the globe and see all <br/> your locations, click on a location to expand <br/> the informations'
          variant='subtitle2'
          classNames='p-2'
          dangerHtml
        />
      </div>
      <Plans />
    </Card>
  );
};

export default ActionPlans;
