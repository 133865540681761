import React from 'react';
import { InputSwitch as PRInputSwitch } from 'primereact/inputswitch';
import PropTypes from 'prop-types';
import styles from './InputSwitch.module.scss';

const InputSwitch = ({
  label,
  checked,
  handleChange,
  classNames,
  inputId,
  isLabelFirst,
}) => {
  const InputSwitchLabel = renderLabel(inputId, label);
  return (
    <div className={styles.inputSwitchWrapper}>
      {isLabelFirst && InputSwitchLabel}
      <PRInputSwitch
        className={classNames}
        checked={checked}
        onChange={(e) => handleChange(e.value)}
        inputId={inputId}
        pt={{ slider: { className: styles.customSlider } }}
      />
      {!isLabelFirst && InputSwitchLabel}
    </div>
  );
};

const renderLabel = (id, title) => <label htmlFor={id}>{title}</label>;

export default InputSwitch;
InputSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  classNames: PropTypes.string,
  checked: PropTypes.bool,
  isLabelFirst: PropTypes.bool,
};

InputSwitch.defaultProps = {
  label: '',
  inputId: '',
  checked: false,
  isLabelFirst: false,
  classNames: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleChange: () => {},
};
