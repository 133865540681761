import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from 'recharts';
import useViewport from '../../../../hooks/useViewport';
import SizeDetector from '../../../../utils/size-detector';
import './Chart.scss';
import { useTranslation } from 'react-i18next';
import Card from '../../../../components/Card/Card';
import Circle from '../../../../components/Circle/Circle';
import Typography from '../../../../components/Typography/Typography';
import { COLORS as colors } from '../../../../assets/constants/COLORS';

const COLORS = [colors.red, colors.yellow, colors.green];

const Example = () => {
  const { width } = useViewport();
  const size = SizeDetector(width);
  const { t } = useTranslation();

  const data = [
    { name: 'dashboard.chart.highRisk', value: 40 },
    { name: 'dashboard.chart.mediumRisk', value: 38 },
    { name: 'dashboard.chart.lowRisk', value: 12 },
  ];

  return (
    <Card radius='large' bgColor='gray-light'>
      <div className='w-full h-full py-3 flex flex-column gap-1'>
        <div className='flex justify-content-evenly text-dark'>
          {data.map((entry, index) => (
            <div
              key={`label-${index}`}
              className={`flex align-items-center gap-1`}
            >
              <Circle color={COLORS[index]} size='small' />
              <Typography
                text={entry.name}
                color='black-dark'
                variant='subtitle2'
              />
            </div>
          ))}
        </div>
        <ResponsiveContainer className='w-full h-full flex align-content-center justify-content-center'>
          <PieChart
            style={{
              width: `${getChartSize(size)}px`,
            }}
          >
            {/* recharts-curve recharts-pie-label-line */}
            <Pie
              style={{
                width: `${getChartSize(size)}px`,
              }}
              data={data}
              dataKey='value'
              cx='50%'
              cy='55%'
              innerRadius={60}
              outerRadius={100}
              label={({ percent, x, y, cx }) => (
                <text
                  x={x}
                  y={y}
                  fill='black'
                  textAnchor={x > cx ? 'start' : 'end'}
                  dominantBaseline='central'
                  style={{
                    fontSize: '1.3vw',
                  }}
                >
                  {`${(percent * 100).toFixed(0)}%`}
                </text>
              )}
            >
              <Label
                value={t('dashboard.chart.title')}
                position='center'
                content={({ viewBox, value }) => {
                  const { cx, cy } = viewBox;
                  return (
                    <text
                      x={cx}
                      y={cy}
                      textAnchor='middle'
                      dominantBaseline='central'
                    >
                      {value.split('\n').map((line, index) => (
                        <tspan
                          style={{
                            fontSize: ['4k', 'qhd'].includes(size)
                              ? '.8vw'
                              : '1rem',
                          }}
                          x={cx}
                          dy={index === 0 ? '-1em' : '1.2em'}
                          key={index}
                        >
                          {line}
                        </tspan>
                      ))}
                    </text>
                  );
                }}
              />
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    COLORS[index] === 'red'
                      ? '#ff003d'
                      : COLORS[index] === 'green'
                        ? '#45f974'
                        : 'yellow'
                  }
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

function getChartSize(size) {
  switch (size) {
    case '4k':
      return 900;
    case 'qhd':
      return 492;
    case 'fhd':
      return 300;
    case 'hdtv':
      return 250;
    case 'hd':
      return 150;
    default:
      return 100;
  }
}
export default Example;
