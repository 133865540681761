import { useNavigate } from 'react-router-dom';
import Card from '../../../components/Card/Card';
import Typography from '../../../components/Typography/Typography';

const containerStyle = {
  paddingLeft: '2vh',
  paddingTop: '2vh',
  paddingBottom: '2vh',
};
const gaps = {
  gapPoint8: { gap: '.8vw' },
  gap1: { gap: '1vw' },
};

export default function LocationCount() {
  const navigate = useNavigate();

  return (
    <Card
      radius='large'
      style={containerStyle}
      onClick={() => navigate('/locations')}
      bgColor='dark'
    >
      <div className='grid m-0 flex align-items-center gap-5 w-full h-full cursor-pointer'>
        <div className='col h-full'>
          <Card bgColor='gray-dark' radius='medium'>
            <div className='flex h-full align-items-center justify-content-center'>
              <div
                className='text-center mt-4 flex flex-column'
                style={gaps.gapPoint8}
              >
                <div>
                  <Typography color='white' text='1.457' variant='h1' />
                </div>
                <div>
                  <Typography
                    text='dashboard.locationCount.totalLocations'
                    variant='body1'
                    color='white'
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div
          className='col flex flex-column justify-content-center'
          style={gaps.gap1}
        >
          <Typography
            dangerHtml
            text='dashboard.locationCount.allLocations'
            variant='h2'
            color='white'
          />
          <Typography
            dangerHtml
            text='dashboard.locationCount.getAccess'
            variant='body1'
            color='white'
          />
        </div>
      </div>
    </Card>
  );
}
