import Tab from '../../../../components/Tab/Tab';
import Species from './Tabs/Species';
import BSA from './Tabs/BSA';

const MapTab = () => {
  return (
    <Tab
      style={{ height: '45vh' }}
      fullWidthHeader
      headers={['Species', 'BSA']}
      bodyContents={[Species, BSA]}
      closable={true}
    />
  );
};

export default MapTab;
