import LocationsTableBody from './LocationTableBody';
import LocationsTableHeader from './LocationTableHeader';
import AddLocation from './AddLocation/AddLocation';
import TablePaginator from '../../../components/MainTable/TablePaginator';

const LocationsTable = ({
  locations,
  onSort,
  onSearch,
  first,
  pageSize,
  totalRows,
  setFirst,
  onPageChange,
}) => {
  const noLocations = true;

  return (
    <div className='flex flex-column h-full gap-1'>
      <LocationsTableHeader noLocations={noLocations} onSearch={onSearch} />
      {noLocations ? (
        <div className='w-full h-full add-location-wrapper'>
          <AddLocation />
        </div>
      ) : (
        <>
          <div className='w-full flex justify-content-end'>
            <TablePaginator
              first={first}
              rows={pageSize}
              totalRecords={totalRows}
              onPageChange={onPageChange}
              setFirst={setFirst}
            />
          </div>
          <LocationsTableBody onSort={onSort} locations={locations} />
        </>
      )}
    </div>
  );
};

export default LocationsTable;
