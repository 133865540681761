import ArableLand from '../../../../../assets/icons/ArableLand';
import ForestTemperate from '../../../../../assets/icons/ForestTemperate';
import Pastureland from '../../../../../assets/icons/Pastureland';
import Plantation from '../../../../../assets/icons/Plantation';
import ShrublandTemperate from '../../../../../assets/icons/ShrublandTemperate';
import UrbanAreas from '../../../../../assets/icons/UrbanAreas';
import { COLORS } from '../../../../../assets/constants/COLORS';

const { orange, red, yellow } = COLORS;
export const natureConditions = [
  {
    title: 'Biodiversity Intactness',
    description: 'Mean Species Abundance ',
    icon: '',
    value: '10%',
    color: red,
  },
  {
    title: 'Biodiversity Importance',
    description: 'Biodiversity sensitive areas',
    value: '3',
    color: red,
  },
  {
    title: 'Threatened species',
    value: '145',
    color: orange,
  },
];

export const natureResiliences = [
  {
    title: 'Keystone Species Richness',
    value: '120',
    color: yellow,
  },
  {
    title: 'Biodiversity sensitive Species',
    description: 'Keystone species at risk',
    value: '80%',
    color: yellow,
  },
];

export const natureExtendsAndFragmentation = [
  {
    title: 'Habitat Integrity',
    color: red,
    description: 'Natural habitat',
  },
  {
    title: 'Habitat Fragmentation',
    description: [
      {
        content: 'Arable Land ',
        icon: <ArableLand />,
        value: '45%',
      },
      {
        content: 'Pastureland',
        icon: <Pastureland />,
        value: '15.4%',
      },
      {
        content: 'Urban Areas',
        icon: <UrbanAreas />,
        value: '12.4%',
      },
      {
        content: 'Forest - Temperate ',
        icon: <ForestTemperate />,
        value: '10.6%',
      },
      {
        content: 'Plantations',
        icon: <Plantation />,
        value: '8.4%',
      },
      {
        content: 'Shrubland - Temperate ',
        icon: <ShrublandTemperate />,
        value: '8.3%',
      },
    ],
    color: yellow,
    value: '10%',
  },
];

export const natureRelatedRisks = [
  {
    title: 'Deforestation Risk',
    value: 'Urbanisation',
    color: yellow,
  },
  {
    title: 'Water Risk',
    color: yellow,
  },
];
