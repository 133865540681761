import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import classes from './Layout.module.scss';
import Header from '../Header/Header';

const Layout = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const isDarkMode = pathname.includes('actions');
  const wrapperClasses = `${classes.wrapper} ${isDarkMode ? classes['bg-black-darkest'] : ''}`;
  return (
    <div className={wrapperClasses}>
      <Header />
      <Outlet />
      {/* <footer>here should be a footer</footer> */}
    </div>
  );
};

export default Layout;
