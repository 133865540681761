import { COLORS } from '../../../../assets/constants/COLORS';
import Button from '../../../../components/Button/Button';
import Card from '../../../../components/Card/Card';
import Circle from '../../../../components/Circle/Circle';
import Typography from '../../../../components/Typography/Typography';
import styles from './Summary.module.scss';
const { orange, red, yellow } = COLORS;

export default function Summary() {
  return (
    <Card radius='large' bgColor='gray-dark'>
      <div className={`${styles['summary-wrapper']} gap-2`}>
        <div>
          <Card radius='large' bgColor='dark'>
            <div className='flex align-items-center justify-content-center py-1'>
              <i
                className={`${styles['serach-icon']} pi pi-search border-circle text-white`}
              />
              <div className='flex flex-column align-items-start px-3'>
                <input
                  className={`${styles['serach-input']} helve-font bg-transparent w-full`}
                  value='REWE Center Valeen'
                />
                <Typography
                  color='gray-medium'
                  text='Valencia 15, 95326, Spain'
                  variant='subtitle2'
                />
              </div>
            </div>
          </Card>
        </div>

        <Card bgColor='dark' radius='medium'>
          <div className='h-full flex flex-column align-items-start gap-6 px-5 py-7 '>
            <Typography
              text='26.5'
              color='yellow'
              variant='h1'
              classNames='mb-3'
            />
            <div className='flex flex-column gap-3'>
              <Typography
                text='PRIORITY SCORE'
                color='white'
                variant='h4'
                format='uppercase'
              />
              <Typography
                text='Your priority score is the globe and see all your locations'
                color='white'
                variant='body1'
                dangerHtml
              />
            </div>
          </div>
        </Card>

        <div>
          <Card bgColor='dark' radius='medium'>
            <div className='flex flex-column px-3 py-2 gap-5'>
              <div className='px-3 pt-2'>
                <Typography
                  text='Relevant reporting'
                  variant='h5'
                  color='white'
                />
                <div className='flex gap-1 w-full mt-1'>
                  <div className='flex gap-1 align-items-center'>
                    <Circle color={red} size='small' />
                    <Typography color='white' text='ESRS E1' variant='body1' />
                  </div>
                  <div className='flex gap-1 align-items-center'>
                    <Circle color={orange} size='small' />
                    <Typography color='white' text='ESRS E2' variant='body1' />
                  </div>
                  <div className='flex gap-1 align-items-center'>
                    <Circle color={yellow} size='small' />
                    <Typography color='white' text='ESRS E3' variant='body1' />
                  </div>
                </div>
              </div>
              <div className='h-3rem'>
                <Button variant='light' size='large'>
                  REPORT
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Card>
  );
}
