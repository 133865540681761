import PropTypes from 'prop-types';
import classes from './TextField.module.scss';
import IconButton from '../IconButton/IconButton';
import Typography from '../Typography/Typography';

const TextField = ({
  name,
  id,
  placeholder,
  icon,
  iconPosition = 'left',
  variant = 'dark',
  type = 'text',
  label,
  radiusType = 'rounded',
}) => {
  const iconStyles = {
    [iconPosition]: '0',
    margin: iconPosition === 'right' ? '0 .2rem 0 0' : '0 0 0 .2rem',
  };

  return (
    <div
      className={`${classes['text-field']} w-full h-full input-wrapper gap-1`}
    >
      {icon && (
        <div style={iconStyles} className={classes['text-field-icon']}>
          <IconButton variant={variant} icon={icon} />
        </div>
      )}
      {label && (
        <Typography
          text={label}
          color={variant === 'dark' ? 'white' : 'black-dark'}
          variant='body1'
        />
      )}
      <input
        placeholder={placeholder}
        name={name}
        id={id}
        type={type}
        className={`${classes[`${variant}-text-field`]} ${classes[radiusType]} w-full`}
      />
    </div>
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(['right', 'left']),
  variant: PropTypes.oneOf(['dark', 'light']),
  type: PropTypes.oneOf(['email', 'number', 'password', 'text']),
  label: PropTypes.string,
  radiusType: PropTypes.oneOf(['rounded', 'semi-rounded']),
};

export default TextField;
