import React from 'react';
import PropTypes from 'prop-types';
import classes from './Button.module.scss';
import { classNames } from 'primereact/utils';

const Button = ({ children, variant, size, onClick, disabled }) => {
  const buttonVariant = variant || 'dark';
  const buttonSize = size || 'small';

  const combinedClasses = classNames(
    classes[buttonVariant + '-button'],
    classes[buttonSize + '-button'],
    'flex justify-content-center align-items-center ',
    classes.buttonStyles
  );

  return (
    <button disabled={disabled} onClick={onClick} className={combinedClasses}>
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.oneOf([
    'dark',
    'light',
    'text-dark',
    'text-light',
    'transparent',
  ]),
  size: PropTypes.oneOf(['small', 'large']),
};
export default Button;
