import React, { useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import Card from '../Card/Card';
import Typography from '../Typography/Typography';
import IconButton from '../IconButton/IconButton';
import './Dropdown.scss';

const Dropdown = ({ items, handleSelect }) => {
  const [activeItem, setActiveItem] = useState(1);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (id) => {
    setActiveItem(id);
    handleSelect?.(id);
    setIsOpen(false);
  };

  const renderedItems =
    items.find((item) => item.id === activeItem)?.text || '';

  return (
    items && (
      <div className={`absolute w-full z-1 ${!isOpen && 'h-full'}`}>
        <Card
          bgColor='gray-light'
          radius='medium'
          className={`flex flex-column ${isOpen && 'pb-2'}`}
        >
          <Card
            radius='medium'
            specificCorners={isOpen && ['tl', 'tr']}
            bgColor='dark'
            className={`flex justify-content-between align-items-center px-3 py-1 h-full`}
          >
            <Typography color='white' text={renderedItems} variant='body1' />
            <div>
              <IconButton
                onClick={() => {
                  setIsOpen((prev) => !prev);
                }}
                icon={`pi pi-angle-${isOpen ? 'up' : 'down'}`}
              />
            </div>
          </Card>

          {isOpen &&
            items.map((item, index) => (
              <React.Fragment key={item.id}>
                <div
                  className='flex align-items-center'
                  onClick={() => handleClick(item.id)}
                >
                  <Card
                    radius='medium'
                    className='dropdown-item flex justify-content-between px-3 py-1 hoverable  px-3 mt-1 hoverable flex-column align-items-start'
                  >
                    <Typography
                      color='black'
                      text={item.text}
                      variant='body1'
                    />
                    {item?.description && (
                      <Typography
                        color='black-dark-opacity'
                        text={item.description}
                        variant='subtitle2'
                      />
                    )}
                  </Card>
                </div>

                {index !== items.length - 1 && (
                  <div className='w-full flex justify-content-center'>
                    <div className='divider' />
                  </div>
                )}
              </React.Fragment>
            ))}
        </Card>
      </div>
    )
  );
};

// Define PropTypes
Dropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleSelect: PropTypes.func,
};

export default Dropdown;
