import { useState } from 'react';
import Button from '../../../components/Button/Button';
import TextField from '../../../components/TextField/TextField';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { items1, items2 } from './data';
import styles from './ReportTableHeader.module.scss';

const RiskTableHeader = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState(null);

  const onSearchChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/\s+/g, ' ');

    const sq = { ...searchQuery, [name]: trimmedValue };
    setSearchQuery(sq);
    onSearch(sq);
  };

  return (
    <div className={styles['report-table-header-wrapper']}>
      <div className={styles['dropdown-container']}>
        <Dropdown items={items1} />
      </div>
      <div className={styles['dropdown-container']}>
        <Dropdown items={items2} />
      </div>
      <div className={styles['textfield-container']}>
        <TextField
          name='name'
          value={searchQuery?.['name']}
          placeholder='Search'
          type='text'
          onChange={onSearchChange}
          variant='light'
          icon='pi pi-search'
          iconPosition='right'
        />
      </div>
      <div className={styles['download-btn']}>
        <Button>Download list</Button>
      </div>
    </div>
  );
};

export default RiskTableHeader;
