import { useTranslation } from 'react-i18next';
import Card from '../../../components/Card/Card';
import Circle from '../../../components/Circle/Circle';
import Typography from '../../../components/Typography/Typography';
import { COLORS } from '../../../assets/constants/COLORS';

const ProfileRow = ({ title, circles }) => (
  <div className='flex justify-content-end align-items-center gap-5'>
    <Typography text={title} variant='body1' color='black-dark' />
    <div className='flex justify-content-end'>
      <div className='grid nogutter'>
        {circles.map((circle, index) => (
          <Circle size='large' key={index} color={circle} />
        ))}
      </div>
    </div>
  </div>
);

const colors = ['red', 'orange', 'yellow', 'green'];

const profiles = (t) => [
  {
    title: t('dashboard.profiles.categories.impactProfile'),
    circles: [COLORS.green, COLORS.green, COLORS.gray, COLORS.gray, COLORS.red],
  },
  {
    title: t('dashboard.profiles.categories.dependencyProfile'),
    circles: [COLORS.green, COLORS.green, COLORS.gray, COLORS.gray, COLORS.red],
  },
  {
    title: t('dashboard.profiles.categories.natureRiskProfile'),
    circles: [
      COLORS.orange,
      COLORS.yellow,
      COLORS.yellow,
      COLORS.yellow,
      COLORS.gray,
    ],
  },
  {
    title: t('dashboard.profiles.categories.climateRiskProfile'),
    circles: [
      COLORS.gray,
      COLORS.orange,
      COLORS.gray,
      COLORS.gray,
      COLORS.gray,
    ],
  },
];

const styles = {
  width: '4vw',
  textAlign: 'center',
};

export default function ProfileStatus() {
  const { t } = useTranslation();

  return (
    <Card radius='large' bgColor='gray-light'>
      <div className='p-4 flex flex-column gap-4 m-0 w-full h-full'>
        <header className='w-full flex align-items-center'>
          <Typography
            text='dashboard.profiles.title'
            color='black-dark'
            variant='h4'
          />

          <div className='flex flex-wrap justify-content-end gap-4 w-full'>
            {['veryHigh', 'high', 'medium', 'low'].map((labelKey, index) => (
              <div key={index} className='flex align-items-center gap-1'>
                <Circle size='small' color={colors[index]} />
                <Typography
                  text={`dashboard.profiles.labels.${labelKey}`}
                  variant='subtitle2'
                  color='black-dark'
                />
              </div>
            ))}
          </div>
        </header>

        <div className='rows-container flex flex-column gap-2'>
          {profiles(t).map((profile, index) => (
            <ProfileRow
              key={index}
              title={profile.title}
              circles={profile.circles}
            />
          ))}
          <div className='grid mt-1'>
            <span className='col-3'></span>
            <div className='col-9 flex justify-content-end'>
              <div className='grid'>
                {['NL', 'PL', 'CA', 'DE', 'DE'].map((text) => (
                  <div style={styles} key={text}>
                    <Typography
                      text={text}
                      color='black-dark'
                      variant='body1'
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
