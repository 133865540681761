import React from 'react';

const Camera = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='25' cy='25' r='25' fill='#191919' />
      <g clipPath='url(#clip0_702_702)'>
        <path
          d='M34.4044 17.9281H31.0294L29.0759 14.5573C28.7794 14.0413 28.2394 13.7256 27.6624 13.7256H22.8818C22.2941 13.7256 21.7488 14.0522 21.4497 14.582L19.5809 17.9281H16.2059C14.8347 17.9281 13.7256 19.0782 13.7256 20.5028V32.5969C13.7256 34.0215 14.8347 35.1717 16.2085 35.1717H34.4071C35.7809 35.1717 36.89 34.0215 36.89 32.5969V20.5028C36.89 19.0782 35.7809 17.9281 34.4071 17.9281H34.4044ZM25.3065 30.0743C23.4774 30.0743 21.9976 28.5399 21.9976 26.6432C21.9976 24.7465 23.4774 23.212 25.3065 23.212C27.1356 23.212 28.6153 24.7465 28.6153 26.6432C28.6153 28.5399 27.1356 30.0743 25.3065 30.0743Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_702_702'>
          <rect
            width='23.1618'
            height='21.4461'
            fill='white'
            transform='translate(13.7256 13.7256)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Camera;
