import React from 'react';
import PropTypes from 'prop-types';
import classes from './Circle.module.scss';

const Circle = ({ size, color }) => {
  const circleSize = size || 'small';
  const circleColor = color || 'red';
  const combinedClasses = [
    classes[`${circleSize}-circle`],
    classes[`${circleColor}-circle`],
  ].join(' ');

  return <div className={combinedClasses}></div>;
};

Circle.propTypes = {
  size: PropTypes.oneOf(['x-small', 'small', 'large']),
  color: PropTypes.oneOf(['red', 'green', 'gray', 'yellow', 'orange']),
};

Circle.defaultProps = {
  size: 'small',
  color: 'red',
};

export default Circle;
