import { useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { classNames } from 'primereact/utils';
import PropTypes from 'prop-types';
import Card from '../Card/Card';
import './MainTable.scss';

const rowClasses = 'text-xs text-white cursor-pointer bg-dark';
const headerClasses = 'text-white uppercase font-light col bg-transparent';

const TableBody = ({
  rows,
  onSort,
  onRowClick,
  columns,
  sortableFields,
  initialSort,
  specialColumns,
}) => {
  const [sort, setSort] = useState({
    field: initialSort.field,
    order: initialSort.order,
  });

  const sortHandler = (event) => {
    if (!event.sortField || !event.sortOrder) {
      console.warn('Invalid sort options provided');
      return;
    }

    const sortObj = { field: event.sortField, order: event.sortOrder };
    setSort(sortObj);
    onSort(sortObj);
  };

  if (!rows) {
    console.error('rows prop is missing');
    return <div>Error: Data is not available</div>;
  }

  const dColumns = columns.map((col) => (
    <Column
      key={col.field}
      field={col.field}
      header={col.header}
      headerClassName={classNames(headerClasses)}
      sortable={sortableFields.includes(col.field)}
      headerStyle={{
        fontSize: '1.1vw',
      }}
      bodyStyle={{
        ...(specialColumns.includes(col.field) && { color: '#45F974' }),
        fontSize: '1.1vw',
      }}
      style={{
        maxWidth: '1vw',
      }}
      align='center'
    />
  ));

  return (
    <Card radius='large' bgColor='dark' className='p-4'>
      <DataTable
        value={rows}
        className='bg-dark test-white'
        rowClassName={() => {
          return classNames(rowClasses);
        }}
        stripedRows
        size='small'
        onRowClick={onRowClick}
        rowHover
        onSort={sortHandler}
        sortField={sort.field}
        sortOrder={sort.order}
      >
        {dColumns}
      </DataTable>
    </Card>
  );
};

TableBody.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onSort: PropTypes.func,
  onRowClick: PropTypes.func,
  sortableFields: PropTypes.arrayOf(PropTypes.string),
  initialSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.number,
  }),
  specialColumns: PropTypes.arrayOf(PropTypes.string),
};

export default TableBody;
