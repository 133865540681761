import { useCallback, useEffect, useState } from 'react';
import LocationsTable from './components/Location';
import { generateLocations } from '../../utils/generators';

const initialParams = {
  field: '',
  order: 1,
  page: 1,
  pageSize: 30,
};

const fetchAndSetLocations = async (
  params,
  setParams,
  setLocations,
  setTotalRows
) => {
  try {
    setParams(params);
    const data = generateLocations({}, params);
    if (!data || !data.features) {
      console.error('Locations data is not available');
      return;
    }
    setLocations(data.features);
    setTotalRows(data.total);
  } catch (error) {
    console.error('Failed to fetch locations', error);
  }
};

export default function Locations() {
  const [params, setParams] = useState(initialParams);
  const [locations, setLocations] = useState([]);
  const [totalRows, setTotalRows] = useState(initialParams.pageSize || 10);
  const [first, setFirst] = useState(1);

  const getLocations = useCallback(
    (params) =>
      fetchAndSetLocations(params, setParams, setLocations, setTotalRows),
    []
  );

  const onPageChange = (page) => {
    getLocations({ ...params, page });
  };

  const onSearch = (searchQuery) => {
    getLocations({ ...initialParams, ...searchQuery });
  };

  const onSort = (sortQuery) => {
    getLocations({ ...initialParams, ...sortQuery });
  };

  useEffect(() => {
    document.title = 'Locations';
    getLocations(initialParams);
  }, [getLocations]);

  return (
    <div className='h-full'>
      <LocationsTable
        onSort={onSort}
        locations={locations}
        onSearch={onSearch}
        first={first}
        pageSize={initialParams.pageSize}
        totalRows={totalRows}
        onPageChange={onPageChange}
        setFirst={setFirst}
      />
    </div>
  );
}
