import RiskTableHeader from './RiskTableHeader';
import TablePaginator from '../../../components/MainTable/TablePaginator';
import RiskTableBody from './RiskTableBody';

const LocationsTable = ({
  locations,
  onSort,
  first,
  pageSize,
  totalRows,
  setFirst,
  onPageChange,
}) => {
  return (
    <div className='flex flex-column h-full gap-1'>
      <RiskTableHeader />

      <>
        <div className='w-full flex justify-content-end'>
          <TablePaginator
            first={first}
            rows={pageSize}
            totalRecords={totalRows}
            onPageChange={onPageChange}
            setFirst={setFirst}
          />
        </div>
        <RiskTableBody onSort={onSort} locations={locations} />
      </>
    </div>
  );
};

export default LocationsTable;
