import React from 'react';
import styles from './MinimizeSoil.module.scss';
import data from './data.json';
import Card from '../../../../components/Card/Card';
import Typography from '../../../../components/Typography/Typography';
import RadioButton from '../../../../components/RadioButton/RadioButton';
import RatingBar from '../../../../components/RatingBar/RatingBar';

const options = ['COMPLETED', 'NON APPLICABLE'];
const MinimizeSoil = () => {
  return (
    <Card
      autoDimensions
      bgColor='dark'
      radius='large'
      className={`${styles.minimizeSoilContainer} pt-6`}
    >
      <Typography
        classNames='pl-5 pb-3 '
        variant='h2'
        text={data.title}
        color='green'
      />
      <Typography
        classNames='pl-5 pb-2 '
        variant='body1'
        text={data.tasks_completed}
        color='gray-medium'
      />
      <div className={styles.tasksWrapper}>
        {data?.tasks.map((task) => (
          <div key={task.id} className={styles.taskCard}>
            <div className={styles.titleSection}>
              <Typography
                text={`${task.id}) ${task.description}`}
                variant='body1'
                color='white'
                classNames='line-height-2'
              />

              <RatingBar impact={task.impact} effort={task.effort} />
            </div>
            <div className='flex'>
              <RadioButton
                options={options}
                variant='dark'
                cardBgColor='dark'
                extraClassNames='align-items-start '
              />
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default MinimizeSoil;
