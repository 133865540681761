import './Dashboard.scss';
import LocationCount from './components/LocationCount';
import ExploreSection from './components/ExploreSection';
import ProfileStatus from './components/ProfileStatus';
import Chart from './components/Chart/Chart';
import ActionCards from './components/Actions';

export default function Dashboard() {
  return (
    <div className='overflow-hidden'>
      <div className='dashboard-wrapper'>
        <div className='grid-nogutter flex h-30vh grid-nogutter-gap'>
          <div className='col-7'>
            <LocationCount />
          </div>
          <div className='grid-nogutter flex gap-2 col'>
            <div className='col'>
              <Chart />
            </div>
            <div className='col flex flex-column h-full'>
              <ActionCards />
            </div>
          </div>
        </div>

        <div className='grid-nogutter flex explore-section-wrapper grid-nogutter-gap'>
          <div className='col-7'>
            <ExploreSection />
          </div>
          <div className='w-full m-0 col'>
            <ProfileStatus />
          </div>
        </div>
      </div>
    </div>
  );
}
